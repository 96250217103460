export function formatUpkeepTimeString(time) {
  const strings = [];

  Object.keys(time).forEach((key) => {
    if (time[key] !== 0) {
      strings.push(`${time[key]} ${key}`);
    }
  });

  return `~${strings.join(", ")}`;
}

export function getTotals(resources) {
  let strings = [];
  let wood = getResourceAmount(resources, "wood");
  let stone = getResourceAmount(resources, "stone");
  let metal = getResourceAmount(resources, "metal");
  let hqm = getResourceAmount(resources, "hqm");

  if (wood > 0) {
    strings.push(wood + " wood");
  }
  if (stone > 0) {
    strings.push(stone + " stone");
  }
  if (metal > 0) {
    strings.push(metal + " metal");
  }
  if (hqm > 0) {
    strings.push(hqm + " hqm");
  }

  return strings.join(", ");
}

function getResourceAmount(resources, type) {
  let r = resources.find((resource) => resource.name === type);
  if (r) {
    return Math.ceil(r.amount);
  }

  return 0;
}
