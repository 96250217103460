export default class CupboardCalculator {
  cupboard;

  constructor(cupboard) {
    this.cupboard = cupboard;
    if (this.cupboard.usedItemSlots === 0) {
      this.calculateDays();
    }
  }

  calculateDays() {
    this.cupboard.addDay();
    if (this.cupboard.hasRemainingItemSlots()) {
      this.calculateDays();
    } else {
      this.cupboard.subtractDay();
      this.calculateHours();
    }
  }

  calculateHours() {
    this.cupboard.addHour();
    if (this.cupboard.hasRemainingItemSlots()) {
      this.calculateHours();
    } else {
      this.cupboard.subtractHour();
      this.calculateMinutes();
    }
  }

  calculateMinutes() {
    this.cupboard.addMinute();
    if (this.cupboard.hasRemainingItemSlots()) {
      this.calculateMinutes();
    } else {
      this.cupboard.subtractMinute();
      this.calculateSeconds();
    }
  }

  calculateSeconds() {
    this.cupboard.addSecond();
    if (this.cupboard.hasRemainingItemSlots()) {
      this.calculateSeconds();
    } else {
      this.cupboard.subtractSecond();
      this.cupboard.updateItemSlots();
    }
  }
}
