export const CUPBOARD_MAX_ITEM_SLOTS = 24;

export const RESOURCES = {
  wood: {
    requiredAmountPerSecond: 0,
    requiredAmountPerMinute: 0,
    requiredAmountPerHour: 0,
    requiredAmountPerDay: 0,
    maxStackSize: 1000,
  },
  stone: {
    requiredAmountPerSecond: 0,
    requiredAmountPerMinute: 0,
    requiredAmountPerHour: 0,
    requiredAmountPerDay: 0,
    maxStackSize: 1000,
  },
  metal: {
    requiredAmountPerSecond: 0,
    requiredAmountPerMinute: 0,
    requiredAmountPerHour: 0,
    requiredAmountPerDay: 0,
    maxStackSize: 1000,
  },
  hqm: {
    requiredAmountPerSecond: 0,
    requiredAmountPerMinute: 0,
    requiredAmountPerHour: 0,
    requiredAmountPerDay: 0,
    maxStackSize: 100,
  },
};
