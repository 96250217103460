import { createGlobalStyle } from "styled-components";
import noise from "../public/img/noise.png";

const GlobalStyle = createGlobalStyle`
  :root {
    // Colors
    --color-grey-1: #1b1b1b;
    --color-grey-2: #151413;
    --color-grey-3: #1f1f1f;
    --color-red-1: #cd412b;
    --color-red-2: #b83a27;
    --color-white-1: #ffffff;
    --color-white-2: #e4dad1;
    --color-white-3: #bab1a8;

    // Fonts
    --font-bebas-neue: "Bebas Neue", sans-serif;
    --font-open-sans: "Open Sans", sans-serif;

    --font-size-huge: 4rem;
    --font-size-big: 2rem;
    --font-size-medium-big: 1.5rem;
    --font-size-medium: 1rem;
    --font-size-small: 0.7rem;
  }

  * {
    box-sizing: border-box;

  }

  html, body, #app {
    height: 100%;
  }

  body {
    margin: 0;
    background-color: var(--color-grey-2);
    color: var(--color-white-3);
    font-family: var(--font-open-sans);
    font-size: 16px;

    &:after {
      background-image: url(${noise});
      content: '';
      pointer-events: none;
      position: fixed;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: .6;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-bebas-neue);
    color: var(--color-white-2);
    font-weight: 500;
    margin: 0;
  }

  h1 {
    font-size: var(--font-size-big);
  }

  a {
    color: var(--color-red-2);
  }
`;

export default GlobalStyle;
