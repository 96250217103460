import styled from "styled-components";
import wood from "../../../public/img/wood.png";
import stones from "../../../public/img/stone.png";
import metal from "../../../public/img/metal.png";
import hqm from "../../../public/img/hqm.png";

export default styled.div`
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;

  &.wood {
    background-image: url(${wood});
  }

  &.stone {
    background-image: url(${stones});
  }

  &.metal {
    background-image: url(${metal});
  }

  &.hqm {
    background-image: url(${hqm});
  }
`;
