import ReactDOM from "react-dom/client";
import App from "./components/App";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ReactGA from "react-ga4";
import GlobalStyles from "./GlobalStyles";

ReactGA.initialize("G-0PGZK7Q08H");

const container = document.getElementById("app");

const root = ReactDOM.createRoot(container);

root.render(
  <BrowserRouter>
    <GlobalStyles />
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  </BrowserRouter>
);
