import styled from "styled-components";
import ResourceForm from "./ResourceForm";
import ToolCupboardTable from "./ToolCupboardTable";
import retrotoolCupboardImage from "../../public/img/retrotoolcupboard.png";
import { SectionSmall, Section, SectionMedium } from "./styled/Section";
import { useState, useEffect } from "react";
import { formatUpkeepTimeString, getTotals } from "../util/stringFormat";
import Cupboard from "../classes/Cupboard.class";
import CupboardCalculator from "../classes/CupboardCalculator.class";
import trackEvent from "../util/trackEvent";
import { useSearchParams } from "react-router-dom";
import { mapQueryString } from "../util/queryString";
import { RESOURCES } from "../constants/resources";
import { FORM_ERROR } from "final-form";
import { canCalculate } from "../util/resourcesValidation";

export default function App() {
  const [resources, setResources] = useState(null);
  const [time, setTime] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = (formValues) => {
    setResources(null);
    setTime(null);

    if (canCalculate(formValues) === false) {
      trackEvent("Resource form", "Submit failed", JSON.stringify(formValues));
      return { [FORM_ERROR]: "Please provide a realistic daily upkeep amount" };
    }

    trackEvent("Resource form", "Submit", JSON.stringify(formValues));

    setSearchParams(mapQueryString(formValues));
    calculate(formValues);
  };

  const calculate = (formValues) => {
    const cupboard = new Cupboard(formValues);
    new CupboardCalculator(cupboard);
    setResources(cupboard.resources);
    setTime(cupboard.time);
  };

  useEffect(() => {
    const entries = Object.fromEntries([...searchParams]);

    const resourceEntries = Object.keys(entries)
      .filter((key) => Object.keys(RESOURCES).includes(key))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: entries[key] });
      }, {});

    if (Object.keys(resourceEntries).length) {
      if (canCalculate(resourceEntries)) {
        calculate(resourceEntries);
      }
    }
  }, []);

  return (
    <Container>
      <ContentWrapper>
        <div>
          <ToolCupboardImage src={retrotoolCupboardImage} alt="Tool cupboard" />
          <h1>Tool Cupboard</h1>
          <SectionSmall>
            <ResourceForm
              searchParams={searchParams}
              handleSubmit={handleSubmit}
              hasCalculation={resources !== null}
            />
          </SectionSmall>
          {resources && time && (
            <>
              <Section>
                <SectionSmall>
                  <h2>{getTotals(resources)}</h2>
                  <h2>{formatUpkeepTimeString(time)}</h2>
                </SectionSmall>
                <ToolCupboardTable resources={resources} />
              </Section>
            </>
          )}
        </div>
        <SectionMedium>
          <Footer>
            Made with ❤️ by{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://twitter.com/ArnovanDoesburg"
              onClick={() => {
                trackEvent("Socials", "Arno");
              }}
            >
              Arno van Doesburg
            </a>{" "}
            &#38;{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://steamcommunity.com/id/ishiki/"
              onClick={() => {
                trackEvent("Socials", "Martin");
              }}
            >
              Martin Heisterkamp
            </a>
          </Footer>
        </SectionMedium>
      </ContentWrapper>
    </Container>
  );
}

const ToolCupboardImage = styled.img`
  width: 130px;
  height: 130px;
`;

const Container = styled.div`
  box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
  background-color: var(--color-grey-1);
  padding: 2rem 0 0 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 1140px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
  text-align: center;
  max-width: 500px;
  flex: 1;
`;

const Footer = styled.div`
  font-size: var(--font-size-small);
  padding: 1rem 0;
`;
