import styled from "styled-components";

export const Section = styled.div`
  margin-top: 32px;
`;

export const SectionMedium = styled.div`
  margin-top: 20px;
`;

export const SectionSmall = styled.div`
  margin-top: 8px;
`;
