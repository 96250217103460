import { useState } from "react";
import styled from "styled-components";

const Button = ({ children, text, disabled, type, onClick }) => {
  const [isSwappingText, setIsSwappingText] = useState(false);

  const swapText = () => {
    setIsSwappingText(true);

    setTimeout(() => {
      setIsSwappingText(false);
    }, 1000);
  };

  return (
    <ButtonElement
      type={type}
      disabled={disabled}
      onClick={(event) => {
        if (onClick !== undefined) {
          onClick(event);
        }
        if (text !== undefined) {
          swapText();
        }
      }}
    >
      {isSwappingText && text}

      {!isSwappingText && children}
    </ButtonElement>
  );
};

const ButtonElement = styled.button`
  background-color: var(--color-red-1);
  color: var(--color-white-1);
  font-family: var(--font-bebas-neue);
  font-size: var(--font-size-medium-big);

  width: 100%;
  padding: 8px 20px;
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
  transition: all 0.15s ease;
  border: none;

  &:disabled {
    background-color: var(--color-grey-2);
    color: #fff;
    cursor: none;
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background-color: var(--color-red-2);
  }
`;

export default Button;
