import styled from "styled-components";
import { Field, Form } from "react-final-form";
import { RESOURCES } from "../constants/resources";
import Button from "./styled/Button";
import TextInput from "./styled/TextInput";
import ResourceImage from "./styled/ResourceImage";
import trackEvent from "../util/trackEvent";
import { SectionSmall } from "./styled/Section";

export default function ResourceForm({ searchParams, handleSubmit }) {
  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit, values, pristine, submitError }) => (
        <form onSubmit={handleSubmit}>
          {submitError && (
            <SectionSmall>
              <SubmitError>{submitError}</SubmitError>
            </SectionSmall>
          )}
          <InputsWrapper>
            {Object.keys(RESOURCES).map((key) => (
              <Field
                name={key}
                key={key}
                initialValue={searchParams.get(key) || undefined}
              >
                {({ value, input }) => (
                  <SectionSmall>
                    <InputWrapper>
                      <ResourceImageWrapper>
                        <ResourceImage className={key} />
                      </ResourceImageWrapper>
                      <TextInput
                        type="number"
                        aria-label={key}
                        placeholder={key}
                        value={value}
                        {...input}
                      />
                    </InputWrapper>
                  </SectionSmall>
                )}
              </Field>
            ))}
            <SectionSmall>
              <Button
                type="submit"
                disabled={Object.keys(values).length < 1 || pristine}
              >
                Calculate
              </Button>
              {navigator && (
                <SectionSmall>
                  <Button
                    text="link copied"
                    type="button"
                    onClick={() => {
                      trackEvent("Resource form", "Share");
                      navigator.clipboard.writeText(window.location.href);
                    }}
                  >
                    Share
                  </Button>
                </SectionSmall>
              )}
            </SectionSmall>
          </InputsWrapper>
        </form>
      )}
    </Form>
  );
}

const InputsWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ResourceImageWrapper = styled.div`
  width: 50px;
  margin-right: 16px;
  background-size: contain;
  background-repeat: no-repeat;
`;

const SubmitError = styled.div`
  padding: 10px 5px;
  background-color: var(--color-red-1);
  color: var(--color-white-1);
  font-family: var(--font-bebas-neue);
  font-size: var(--font-size-medium-big);
`;
