import CupboardResource from "./CupboardResource.class";
import { CUPBOARD_MAX_ITEM_SLOTS } from "../constants/resources";

export default class Cupboard {
  maxItemSlots = CUPBOARD_MAX_ITEM_SLOTS;
  usedItemSlots = 0;
  resources = [];
  time = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  constructor(formValues) {
    Object.keys(formValues).forEach((resource) => {
      let requiredAmountPerDay = Number(formValues[resource]);
      this.addResource(resource, requiredAmountPerDay);
    });
  }

  addResource(resourceName, amount) {
    if (resourceName === "hqm") {
      this.resources.push(new CupboardResource(resourceName, amount, 10, 100));
    } else {
      this.resources.push(new CupboardResource(resourceName, amount));
    }
  }

  updateItemSlots() {
    this.usedItemSlots = 0;
    this.resources.forEach((resource) => {
      resource.updateStackCount();
      this.usedItemSlots += resource.stackCount;
    });
  }

  hasRemainingItemSlots() {
    this.updateItemSlots();
    return this.maxItemSlots >= this.usedItemSlots;
  }

  addDay() {
    this.resources.forEach((resource) => {
      resource.addDay();
    });
    this.time.days++;
  }

  addHour() {
    this.resources.forEach((resource) => {
      resource.addHour();
    });
    this.time.hours++;
  }

  addMinute() {
    this.resources.forEach((resource) => {
      resource.addMinute();
    });
    this.time.minutes++;
  }

  addSecond() {
    this.resources.forEach((resource) => {
      resource.addSecond();
    });
    this.time.seconds++;
  }

  subtractDay() {
    this.resources.forEach((resource) => {
      resource.subtractDay();
    });
    this.time.days -= 1;
  }

  subtractHour() {
    this.resources.forEach((resource) => {
      resource.subtractHour();
    });
    this.time.hours -= 1;
  }

  subtractMinute() {
    this.resources.forEach((resource) => {
      resource.subtractMinute();
    });
    this.time.minutes -= 1;
  }

  subtractSecond() {
    this.resources.forEach((resource) => {
      resource.subtractSecond();
    });
    this.time.seconds -= 1;
  }
}
