import styled from "styled-components";
import { CUPBOARD_MAX_ITEM_SLOTS } from "../constants/resources";
import ResourceImage from "./styled/ResourceImage";

export default function ToolCupboardTable({ resources }) {
  const TOOL_CUPBOARD_ITEMS_PER_ROW = 6;
  const mappedResources = [];

  resources.forEach((resource) => {
    let total = Math.round(Math.ceil(resource.amount));
    let rest = total % resource.maxStackSize;
    total -= rest;

    for (let i = 0; i < total / resource.maxStackSize; i++) {
      mappedResources.push({
        resource: resource.name,
        count: resource.maxStackSize,
      });
    }

    if (rest > 0) {
      mappedResources.push({
        resource: resource.name,
        count: rest,
      });
    }
  });

  while (mappedResources.length < CUPBOARD_MAX_ITEM_SLOTS) {
    mappedResources.push({
      resource: null,
      count: null,
    });
  }

  const resourceArrays = [];

  for (
    let i = 0;
    i < mappedResources.length;
    i += TOOL_CUPBOARD_ITEMS_PER_ROW
  ) {
    resourceArrays.push(
      mappedResources.slice(i, i + TOOL_CUPBOARD_ITEMS_PER_ROW)
    );
  }

  return (
    <Table>
      <tbody>
        {resourceArrays.map((resources, index) => (
          <tr key={index}>
            {resources.map(({ resource, count }, index) => (
              <TableData key={index}>
                {count !== null && (
                  <>
                    <ResourceCount>
                      <ResourceCountPrefix>x</ResourceCountPrefix>
                      {count}
                    </ResourceCount>
                    <ResourceImage className={resource} />
                  </>
                )}
              </TableData>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  margin: 0 auto;
`;

const ResourceCountPrefix = styled.span`
  font-size: var(--font-size-medium);
`;

const ResourceCount = styled.span`
  font-family: var(--font-bebas-neue);
  font-size: var(--font-size-medium);
  position: absolute;
  bottom: 5px;
  right: 5px;
  line-height: 0.7;
`;

const TableData = styled.td`
  position: relative;
  width: 60px;
  height: 60px;
  padding: 5px;
  border: 1px solid var(--color-grey-2);

  @media only screen and (min-width: 500px) {
    width: 75px;
    height: 75px;
  }
`;
