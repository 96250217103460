export default class CupboardResource {
  name;
  inputMinimum;
  maxStackSize;

  requiredAmountPerDay;
  requiredAmountPerHour;
  requiredAmountPerMinute;
  requiredAmountPerSecond;

  amount = 0;
  stackCount = 0;

  constructor(
    name,
    requiredAmountPerDay,
    inputMinimum = 10,
    maxStackSize = 1000
  ) {
    this.name = name;
    this.requiredAmountPerDay = requiredAmountPerDay;
    this.requiredAmountPerHour = this.requiredAmountPerDay / 24;
    this.requiredAmountPerMinute = this.requiredAmountPerDay / 1440;
    this.requiredAmountPerSecond = this.requiredAmountPerDay / 86400;
    this.inputMinimum = inputMinimum;
    this.maxStackSize = maxStackSize;
  }

  addDay() {
    this.amount += this.requiredAmountPerDay;
  }

  addHour() {
    this.amount += this.requiredAmountPerHour;
  }

  addMinute() {
    this.amount += this.requiredAmountPerMinute;
  }

  addSecond() {
    this.amount += this.requiredAmountPerSecond;
  }

  subtractDay() {
    this.amount -= this.requiredAmountPerDay;
  }

  subtractHour() {
    this.amount -= this.requiredAmountPerHour;
  }

  subtractMinute() {
    this.amount -= this.requiredAmountPerMinute;
  }

  subtractSecond() {
    this.amount -= this.requiredAmountPerSecond;
  }

  updateStackCount() {
    this.stackCount = Math.round(Math.ceil(this.amount / this.maxStackSize));
  }
}
