import styled from "styled-components";

export default styled.input`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  width: 100%;
  background-color: var(--color-grey-2);
  color: var(--color-white-2);
  font-family: var(--font-bebas-neue);
  border: 2px solid transparent;
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
  transition: all 0.15s ease;

  &:focus {
    border: 2px solid rgba(186, 177, 168, 0.2);
    outline: 0;
  }

  &::placeholder {
    opacity: 0.5;
    color: var(--color-white-1);
  }
`;
